var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "detail-company-modal",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('sepay.columns.details')
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.fullName')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.fullName')
    },
    model: {
      value: _vm.item.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "fullName", $$v);
      },
      expression: "item.fullName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.shortName')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.shortName')
    },
    model: {
      value: _vm.item.shortName,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "shortName", $$v);
      },
      expression: "item.shortName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('sepay.columns.status')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('sepay.columns.status'),
      "options": _vm.status,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('sepay.status.' + data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('sepay.status.' + data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.item.status,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "status", $$v);
      },
      expression: "item.status"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: " d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.saveCompany
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.hide('detail-company-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }