<template>
  <b-modal
    :id="`detail-company-modal`"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="$t('sepay.columns.details')"
  >
    <IAmOverlay :loading="loading">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group :label="$t('sepay.form.fullName')">
              <b-form-input
                v-model="item.fullName"
                :placeholder="$t('sepay.form.ph.fullName')"
                class="bg-white"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('sepay.form.shortName')">
              <b-form-input
                v-model="item.shortName"
                class="bg-white"
                :placeholder="$t('sepay.form.ph.shortName')"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">

            <b-form-group
              label-class="h5 py-0"
              :label="$t('sepay.columns.status')"
            >
              <v-select
                v-model="item.status"
                :placeholder="$t('sepay.columns.status')"
                :options="status"
                :clearable="false"
              >
                <template #option="data">
                  <span>
                    {{ $t('sepay.status.' + data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t('sepay.status.' + data.label) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div class=" d-flex-center gap-3">
        <b-button
          variant="info"
          @click="saveCompany"
        >
          {{ $t('save') }}
        </b-button>
        <b-button
          variant="secondary"
          @click="() => this.$bvModal.hide('detail-company-modal')"
        >
          {{ $t('cancel') }}
        </b-button>
      </div>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    status: {
      type: Array,
      default: null,
    },
  },

  setup(props, { emit }) {
    async function saveCompany() {
      await emit('save')
      this.$bvModal.hide('detail-company-modal')
    }
    return { saveCompany }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
